
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import mAxiosApi from "@/api";
import { ElNotification } from 'element-plus'
import { use } from "element-plus/lib/locale";

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);

    const registration = Yup.object().shape({
      name: Yup.string().required().label("First Name"),
      surname: Yup.string().required().label("Second Name"),
      phone: Yup.string().min(10).required().label("phone"),
      email: Yup.string().min(4).required().email().label("Email"),
      password: Yup.string().min(4).required().label("Password"),
      cpassword: Yup.string()
        .min(4)
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .label("Password Confirmation"),
      toc: Yup.boolean().oneOf([true]).required().label("Conditions"),

    });

    const onSubmitRegister = async (values) => {
      
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      

      // Dummy delay
      setTimeout(async () => {
        // Send login request
        const newAccount = await mAxiosApi.post("/addEditUser", values);
        console.log(newAccount);

        if(newAccount.data.results == 'error'){
          ElNotification({
            title: 'Error',
            message: newAccount.data.message,
            type: 'error',
          })
        }
        else{
          ElNotification({
            title: 'Succes',
            message: "Votre compte a bien été crée !",
            type: 'success',
          })
          // Go to page after successfully login
            const login = {username: values.email, password: values.password};
            console.log(login);
              
            store.dispatch(Actions.LOGIN, login)
            .then(function () {
            router.push({ name: "board" });
  
            });
         
    }
        
         

        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 2000);
    };


   

    return {
      registration,
      onSubmitRegister,
      submitButton,
    };
  },
});
