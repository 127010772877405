<template>
  <!--begin::Wrapper-->
  <div class="p-10 p-lg-15 mx-auto">
    <!--begin::Form-->
    <Form
      class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      novalidate="novalidate"
      @submit="onSubmitRegister"
      id="kt_login_signup_form"
      :validation-schema="registration"
    >
      <!--begin::Heading-->
      <div class="mb-10 text-center">
        <!--begin::Title-->
        <h1 class="text-dark mb-3">Création de compte utilisateur</h1>
        <!--end::Title-->

        <!--begin::Link-->
        <div class="text-gray-400 fw-bold fs-4">
          Vous avez déjà un compte ?

          <router-link to="/sign-in" class="link-primary fw-bolder">
            Se connecter
          </router-link>
        </div>
        <!--end::Link-->
      </div>
      <!--end::Heading-->

      <!--begin::Action-->
      <button
          type="submit"
          ref="submitButton"
          id="kt_sign_in_submit"
          class="btn btn-lg btn-light-primary w-100 mb-5 mt-2"
        >
          <img style="width: 30px" src="https://www.freeiconspng.com/thumbs/linkedin-logo-png/linkedin-logo-3.png">
          <span class="indicator-label"> Se connecter via Linkedin </span>
        </button>
      <!--end::Action-->

      <!--begin::Separator-->
      <div class="d-flex align-items-center mb-10">
        <div class="border-bottom border-gray-300 mw-50 w-100"></div>
        <span class="fw-bold text-gray-400 fs-7 mx-2">OU</span>
        <div class="border-bottom border-gray-300 mw-50 w-100"></div>
      </div>
      <!--end::Separator-->

      <!--begin::Input group-->
      <div class="row fv-row mb-7">
        <!--begin::Col-->
        <div class="col-6 hide-small">
          <label class="form-label fw-bolder text-dark fs-6">Prénom</label>
          <Field
            class="form-control form-control-lg form-control-solid"
            type="text"
            placeholder=""
            name="name"
            autocomplete="off"
            
          />
          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage name="name" />
            </div>
          </div>
        </div>
        <!--end::Col-->

        <!--begin::Col-->
        <div class="col-6">
          <label class="form-label fw-bolder text-dark fs-6">Nom</label>
          <Field
            class="form-control form-control-lg form-control-solid"
            type="text"
            placeholder=""
            name="surname"
            autocomplete="off"
           
          />
          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage name="surname" />
            </div>
          </div>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="row fv-row mb-7">
      <div class="fv-row mb-7">
        <label class="form-label fw-bolder text-dark fs-6">Société</label>
        <Field
          class="form-control form-control-lg form-control-solid"
          type="text"
          placeholder=""
          name="societe"
          autocomplete="off"
         
        />
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="email" />
          </div>
        </div>
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="col-6">
        <label class="form-label fw-bolder text-dark fs-6">Téléphone</label>
        <Field
          class="form-control form-control-lg form-control-solid"
          type="text"
          placeholder=""
          name="phone"
          autocomplete="off"
         
        />
      </div>  
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="col-6">
        <label class="form-label fw-bolder text-dark fs-6">Email</label>
        <Field
          class="form-control form-control-lg form-control-solid"
          type="email"
          placeholder=""
          name="email"
          autocomplete="off"
        />
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="email" />
          </div>
        </div>
      </div>
    </div>
      <!--end::Input group-->

    <div class="row fv-row mb-7">
      <!--begin::Input group-->
      <div class="col-6" data-kt-password-meter="true">
        <!--begin::Wrapper-->
        <div class="mb-1">
          <!--begin::Label-->
          <label class="form-label fw-bolder text-dark fs-6">Mot de passe</label>
          <!--end::Label-->

          <!--begin::Input wrapper-->
          <div class="position-relative mb-3">
            <Field
              class="form-control form-control-lg form-control-solid"
              type="password"
              placeholder=""
              name="password"
              autocomplete="off"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="password" />
              </div>
            </div>
          </div>
          <!--end::Input wrapper-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Input group--->

      <!--begin::Input group-->
      <div class="col-6">
        <label class="form-label fw-bolder text-dark fs-6">Confirmer le mot de passe</label>
        <Field
          class="form-control form-control-lg form-control-solid"
          type="password"
          placeholder=""
          name="cpassword"
          autocomplete="off"
        />
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="cpassword" />
          </div>
        </div>
      </div>
      <!--end::Input group-->
    </div>


      <!--begin::Input group-->
      <div class="fv-row mb-10">
        <label class="form-check form-check-custom form-check-solid">
          <Field
            class="form-check-input"
            type="checkbox"
            name="toc"
            value="1"
          />
          <span class="form-check-label fw-bold text-gray-700 fs-6">
            Vous acceptez les 
            <a href="#" class="ms-1 link-primary">Termes et conditions</a>.
          </span>
        </label>
        
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="toc" />
          </div>
        </div>
      </div>
      <!--end::Input group-->

      <!--begin::Actions-->
      <div class="text-center">
        <button
          id="kt_sign_up_submit"
          ref="submitButton"
          type="submit"
          class="btn btn-lg btn-primary"
        >
          <span class="indicator-label"> Créer votre compte Stock Checker </span>
          <span class="indicator-progress">
            Please wait...
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </button>
      </div>
      <!--end::Actions-->
    </Form>
    <!--end::Form-->
  </div>
  <!--end::Wrapper-->
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import mAxiosApi from "@/api";
import { ElNotification } from 'element-plus'
import { use } from "element-plus/lib/locale";

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);

    const registration = Yup.object().shape({
      name: Yup.string().required().label("First Name"),
      surname: Yup.string().required().label("Second Name"),
      phone: Yup.string().min(10).required().label("phone"),
      email: Yup.string().min(4).required().email().label("Email"),
      password: Yup.string().min(4).required().label("Password"),
      cpassword: Yup.string()
        .min(4)
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .label("Password Confirmation"),
      toc: Yup.boolean().oneOf([true]).required().label("Conditions"),

    });

    const onSubmitRegister = async (values) => {
      
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      

      // Dummy delay
      setTimeout(async () => {
        // Send login request
        const newAccount = await mAxiosApi.post("/addEditUser", values);
        console.log(newAccount);

        if(newAccount.data.results == 'error'){
          ElNotification({
            title: 'Error',
            message: newAccount.data.message,
            type: 'error',
          })
        }
        else{
          ElNotification({
            title: 'Succes',
            message: "Votre compte a bien été crée !",
            type: 'success',
          })
          // Go to page after successfully login
            const login = {username: values.email, password: values.password};
            console.log(login);
              
            store.dispatch(Actions.LOGIN, login)
            .then(function () {
            router.push({ name: "board" });
  
            });
         
    }
        
         

        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 2000);
    };


   

    return {
      registration,
      onSubmitRegister,
      submitButton,
    };
  },
});
</script>
